import React from "react";
import { Link, graphql } from "gatsby";
import parse from "html-react-parser";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import { GatsbyImage } from "gatsby-plugin-image";
import { Button, Card } from "react-bootstrap";

const PostTemplate = ({ data: { post, posts, site } }) => {
	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${site.siteMetadata.siteUrl}`,
					id: `${site.siteMetadata.siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Blog",
				item: {
					url: `${site.siteMetadata.siteUrl}/blog`,
					id: `${site.siteMetadata.siteUrl}/blog`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: `${post.title}`,
				item: {
					url: `${site.siteMetadata.siteUrl}/blog/${post.slug}`,
					id: `${site.siteMetadata.siteUrl}/blog/${post.slug}`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={`${post.title} | Dental House Exeter`}
				description={parse(post.excerpt)}
				language="en"
				openGraph={{
					title: `${post.title} | Dental House Exeter`,
					description: `${parse(post.excerpt)}`,
					url: `${site.siteMetadata.siteUrl}/blog/${post.slug}`,
					type: "article",
					images: [
						{
							url: `${post.blogFields.featuredImage.node.localFile.publicURL}`,
							width: `${post.blogFields.featuredImage.node.localFile.childImageSharp.original.width}`,
							height: `${post.blogFields.featuredImage.node.localFile.childImageSharp.original.height}`,
							alt: `${post.blogFields.featuredImage.node.altText}`,
						},
					],
				}}
			/>
			<section>
				<div className="bg-primary pt-5 pt-lg-7 pb-10">
					<Container>
						<h1 className="text-white  text-center mb-2  display-4 ">
							{post.title}
						</h1>
					</Container>
				</div>

				<Container>
					<Row className="justify-content-center">
						<Col lg={10} xl={8}>
							<div
								style={{
									top: "-6rem",
								}}
								className="position-relative"
							>
								<GatsbyImage
									style={{ maxHeight: "550px" }}
									className=" w-100 "
									image={
										post.blogFields.featuredImage.node.localFile.childImageSharp
											.gatsbyImageData
									}
									alt={post.blogFields.featuredImage?.node.altText}
								/>
							</div>
						</Col>
					</Row>
				</Container>
			</section>

			<section>
				<Container>
					<Row className="justify-content-center">
						<Col
							className="position-relative"
							style={{
								top: "-4rem",
							}}
							lg={10}
							xl={8}
						>
							{parse(post.content)}
							<Row>
								<Col className="text-end">
									<Link to="/blog" className="text-secondary-link">
										More posts
									</Link>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pb-5 pb-lg-7">
				<Container>
					<Row>
						<Col className="text-center text-primary   mt-lg-5">
							<h2 className=" display-4 pb-5 ">Latest blog posts</h2>
						</Col>
					</Row>
					<Row className="g-xl-6 g-4 h-100 ">
						{posts.nodes.map((postOther) => (
							<Col style={{ minHeight: "100%" }} className="" md={6} lg={4}>
								<Card className="h-100 position-relative">
									<Card.Img
										as={GatsbyImage}
										variant="top"
										style={{ maxHeight: "254px" }}
										image={
											postOther.blogFields.featuredImage.node.localFile
												.childImageSharp.gatsbyImageData
										}
										alt={postOther.blogFields.featuredImage.node.altText}
									/>
									<Card.Body className="p-3 pb-6">
										<Card.Title className="text-secondary">
											{postOther.title}
										</Card.Title>
										<Card.Text>{parse(postOther.excerpt)}</Card.Text>
										<div className="position-absolute bottom-0 start-0 p-3">
											<Button
												as={Link}
												to={`/blog/${postOther.slug}`}
												className="px-3  w-100 w-md-auto text-white mt-1"
												variant="primary"
											>
												{" "}
												Read More
											</Button>
										</div>
									</Card.Body>
								</Card>
							</Col>
						))}
					</Row>
				</Container>
			</section>
		</Layout>
	);
};

export default PostTemplate;

export const pageQuery = graphql`
	query BlogPostById($id: String!) {
		post: wpPost(id: { eq: $id }) {
			id
			excerpt
			slug
			content

			title

			blogFields {
				featuredImage {
					node {
						altText
						localFile {
							childImageSharp {
								original {
									height
									width
								}
								gatsbyImageData(
									formats: [AUTO, WEBP]
									quality: 100
									transformOptions: { cropFocus: CENTER, fit: CONTAIN }
									layout: CONSTRAINED
									placeholder: BLURRED
								)
							}
						}
					}
				}
			}
		}

		posts: allWpPost(
			sort: { order: DESC, fields: dateGmt }

			filter: { id: { ne: $id }, title: { ne: "Website Images" } }
		) {
			nodes {
				dateGmt
				title
				excerpt
				slug
				blogFields {
					featuredImage {
						node {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
				}
			}
		}

		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
